import { BodyAndNavigation } from "../../App/theme/BodyAndNavigation"
import { Header } from "../../App/theme/Header"

const MembersPage = () => {
    return (
        <>

                <div className="p-8">
                    <h1 className="text-xl pb-8 font-semibold">This page is not yet available. Take a seat!</h1>
                    <img src="/images/miu-bench-2.jpg" className="rounded-xl"/>
                </div>
        </>
    )
}

export default MembersPage