import { ColorButton } from "../../Style"
import { useProductPageStore } from "../../store";

export const ColorOptionSection = ({ Color, label, options }) => {

    const {
        state : {
            imageSliderStoreRef
        }
    } = useProductPageStore()

    return (
        <div className="pb-4 px-4 item-color">
            <span className="font-semibold text-lg">{label}: </span>
            <div className="inline-block">
                <Color render={color => <>
                    <div className="flex gap-2">
                        {options.map((option, index) =>
                            <ColorButton key={option.code} selected={color === option.code} onClick={() => { Color.set(option.code); imageSliderStoreRef.current.goToSelected(option.imgIndex) }}>{option.label}</ColorButton>
                        )}
                    </div>
                </>} />
            </div>
        </div>
    )
}