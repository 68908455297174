import { useNavigate, useParams } from "react-router-dom"
import { HeaderContainer } from "./Style"

export const Header = () => {

    const params = useParams()
    const navigate = useNavigate()

    return (
        <HeaderContainer>
            <div className="inline-block cursor-pointer" onClick={() => navigate('/'+params.username)}>
                <div className="h-[48px] flex items-center">
                    <img className="h-[48px]" src="/images/profilePic.jpg" alt="playfulMiu_vtubespace_profile"/>
                    <h2 className="text-[24px] ml-1 font-semibold sc-color-headerTitle">Playful Miu</h2>
                </div>
            </div>
        </HeaderContainer>
    )
}