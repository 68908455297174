import { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import GlobalStyle from './theme/global-styles'
import { VtuberNavigation } from '../pages/VtuberNavigation/index.js'

const Home = lazy(() => import('../pages/Home/index.js'))
const Welcome = lazy(() => import('../pages/Welcome/index.js'))
const Shop = lazy(() => import('../pages/Shop/index.js'))
const ProductPage = lazy(() => import('../pages/ProductPage/index.js'))
const StreamPage = lazy(() => import('../pages/Stream/index.js'))
const GamesPage = lazy(() => import('../pages/Games/index.js'))
const MembersPage = lazy(() => import('../pages/Members/index.js'))

export const App = () => {

    return (
        <BrowserRouter>

            <GlobalStyle />

            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route exact path='/' element={<Welcome />} />
                    <Route path='/:username/*' element={<VtuberNavigation />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}