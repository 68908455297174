
import { createContext, useContext } from "react"
import { useValue } from "../../../components/base/hooks/useValue.js"
import { load } from "./functions/load.js"

export const HomePageStoreContext = createContext()

export const useHomePageStore = () => useContext(HomePageStoreContext)

export const MakeHomePageStore = () => {
    const state = {
        WelcomeText : useValue(""),

    }

    return {
        state,
        load : load(state)
        
    }


}