import styled from "styled-components";

export const Style = styled.div`
    display: inline-block;
    width: 100%;
    
    .box-visible {
        opacity: 1;
        animation: AnimatedMessageBox-fadeIn 0.7s ease;

        @keyframes AnimatedMessageBox-fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    .box-hidden {
        opacity: 0;
        animation: AnimatedMessageBox-fadeOut 0.7s ease;

        @keyframes AnimatedMessageBox-fadeOut {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
    }
`

export const Box = styled.div`
    display: inline-block;
    width: 100%;
    padding: 8px;
    background-color: ${props => props.theme.colors.black(0,0,0.85
        )};
    border-radius: 8px;
    color: white;
    transition: all 0.6s ease;
    opacity: 0;
    min-height: 100px;
    text-align: left;

    @media screen and (min-width: 640px){
        padding: 16px;
    }
`