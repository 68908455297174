import { useNavigate, useParams } from "react-router-dom"
import { BodySection, FlexContainer, Navigation, NavigationIcon, NavigationMenuContainer } from "./Style"

export const BodyAndNavigation = ({children}) => {

    const navigate = useNavigate()
    const params = useParams()

    //get page code
    let pageCode = '';
    if(window.location.pathname.includes('/shop')){ pageCode = 'SHOP' }
    else if(window.location.pathname.includes('/stream')){ pageCode = 'STREAM' }
    else if(window.location.pathname.includes('/members')){ pageCode = 'MEMBERS' }
    else if(window.location.pathname.includes('/games')){ pageCode = 'GAMES' }

    const onShopClick = () => {
        navigate('/'+params.username+'/shop')
    }

    const onStreamClick = () => {
        navigate('/'+params.username+'/stream')
    }

    const onMembersClick = () => {
        navigate('/'+params.username+'/members')
    }

    const onGamesClick = () => {
        navigate('/'+params.username+'/games')
    }

    return (
        <FlexContainer>
            <BodySection>
                {children}
            </BodySection>
            <Navigation>
                <NavigationMenuContainer>
                    <NavigationIcon onClick={onStreamClick} selected={pageCode === "STREAM"}>
                        <i className="fa-solid fa-signal-stream"></i>
                        <div>Stream</div>
                    </NavigationIcon>

                    <NavigationIcon onClick={onShopClick} selected={pageCode === "SHOP"}>
                        <i className="fa-solid fa-shop"></i>
                        <div>Shop</div>
                    </NavigationIcon>

                    <NavigationIcon onClick={onMembersClick} selected={pageCode === "MEMBERS"}>
                        <i className="fa-solid fa-star"></i>
                        <div>Members</div>
                    </NavigationIcon>

                    <NavigationIcon onClick={onGamesClick} selected={pageCode === "GAMES"}>
                        <i className="fa-solid fa-gamepad-modern"></i>
                        <div>Games</div>
                    </NavigationIcon>
                </NavigationMenuContainer>
            </Navigation>
        </FlexContainer>
    )
}