//Miu color palette: https://coolors.co/69bee4-eda7c1-323030-c03221-94fbab-ffe156

export const themeSettings = () => ({
    colors: {
        headerTitle: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(199, ${Math.max(0,Math.min(100,69+hueOffset))}%, ${Math.max(0,Math.min(100,65+brightnessOffset))}%, ${opactiy})`,
        black: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(0, ${Math.max(0,Math.min(100,2+hueOffset))}%, ${Math.max(0,Math.min(100,19+brightnessOffset))}%, ${opactiy})`,
        pageHeading: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(0, ${Math.max(0,Math.min(100,2+hueOffset))}%, ${Math.max(0,Math.min(100,19+brightnessOffset))}%, ${opactiy})`,
        navigationButton: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(0, ${Math.max(0,Math.min(100,2+hueOffset))}%, ${Math.max(0,Math.min(100,29+brightnessOffset))}%, ${opactiy})`,
        navigationButtonBG: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(338, ${Math.max(0,Math.min(100,66+hueOffset))}%, ${Math.max(0,Math.min(100,79+brightnessOffset))}%, ${opactiy})`,
        primary: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(338, ${Math.max(0,Math.min(100,66+hueOffset))}%, ${Math.max(0,Math.min(100,79+brightnessOffset))}%, ${opactiy})`,
        spinnerLoader: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(338, ${Math.max(0,Math.min(100,66+hueOffset))}%, ${Math.max(0,Math.min(100,79+brightnessOffset))}%, ${opactiy})`,

        secondary: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(152, ${Math.max(0,Math.min(100,17+hueOffset))}%, ${Math.max(0,Math.min(100,46+brightnessOffset))}%, ${opactiy})`,
        warning: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(35, ${Math.max(0,Math.min(100,83+hueOffset))}%, ${Math.max(0,Math.min(100,57+brightnessOffset))}%, ${opactiy})`,
        bg: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(17, ${Math.max(0,Math.min(100,56+hueOffset))}%, ${Math.max(0,Math.min(100,16+brightnessOffset))}%, ${opactiy})`,
        black: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(240, ${Math.max(0,Math.min(100,22+hueOffset))}%, ${Math.max(0,Math.min(100,4+brightnessOffset))}%, ${opactiy})`,
        white: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(53, ${Math.max(0,Math.min(100,76+hueOffset))}%, ${Math.max(0,Math.min(100,94+brightnessOffset))}%, ${opactiy})`,
        error: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(11, ${Math.max(0,Math.min(100,100+hueOffset))}%, ${Math.max(0,Math.min(100,34+brightnessOffset))}%, ${opactiy})`,
        success: (hueOffset=0, brightnessOffset=0, opactiy=1) => `hsla(152, ${Math.max(0,Math.min(100,17+hueOffset))}%, ${Math.max(0,Math.min(100,46+brightnessOffset))}%, ${opactiy})`
    }
})