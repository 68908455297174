export const loadImages = (state,imageUrlList) => () => {
    if(state.currentImgIndex.current > state.maxIndexBrowsed)
    {
        
        state.maxIndexBrowsed = state.currentImgIndex.current
        let children = state.containerRef.current.children

        for(let i=state.currentImgIndex.current; i <= state.currentImgIndex.current+6; i++)
        {
            if(!state.imagesLoaded[i])
            {
                state.imagesLoaded[i] = true
                if(children[i])
                {
                    children[i].style.backgroundImage = `url(${imageUrlList[i]})`
                }
            }
        }
    }
}