export const onMouseEnter = (state,imageUrlList) => () => {
    if(!state.secondImageLoaded)
    {
        state.secondImageLoaded = true
        let children = state.containerRef.current.children
        if(children[1])
        {
            children[1].style.backgroundImage = `url(${imageUrlList[1]})`
        }
    }
}