import { useValue } from "../../../components/base/hooks/useValue"
import { loadStripe } from '@stripe/stripe-js'
import { PaypalForm } from "../PaypalForm"
import { useProductPageStore } from "../store"
import { PaypalPaymentSection } from "./PaypalPaymentSection/index.js"
import { StripePaymentSection } from "./StripePaymentSection/index.js"

export const PaymentOptionSection = ({paymentOption}) => {


    return (
        <div>
            {paymentOption=== "PAYPAL" && 
            <PaypalPaymentSection/> }

            {paymentOption=== "STRIPE" && 
            <StripePaymentSection/> }
            
        </div>
    )
}