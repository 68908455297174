export const onPreviousClick = (state) => (e) => {
    e.stopPropagation()

    if(state.currentImgIndex.current > 0)
    {
        state.currentImgIndex.current--;
        state.containerRef.current.scrollTo({
            left: state.containerRef.current.getBoundingClientRect().width*state.currentImgIndex.current,
            behavior: "smooth",
        });

        setTimeout(() => {
            state.containerRef.current.scrollTo({
                left: state.containerRef.current.getBoundingClientRect().width*state.currentImgIndex.current,
                behavior: "smooth",
            });
        },50)

        if(!state.RightArrowAvailable.get())
        {
            state.RightArrowAvailable.set(true)
        }

        if(state.currentImgIndex.current <= 0)
        {
            state.LeftArrowAvailable.set(false)
        }
    }
}