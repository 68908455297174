import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

/*body {
    background-color: ${props => props.theme.colors.bg()};
    color: ${props => props.theme.colors.white()};
}*/

.sc-color-headerTitle {
    color: ${props => props.theme.colors.headerTitle()};
}

.sc-color-navigationButton {
    color: ${props => props.theme.colors.black(0,20)};
}

.sc-color-navigationButtonBG {
    background-color: ${props => props.theme.colors.navigationButton()};
}

.sc-color-pageHeading {
    color: ${props => props.theme.colors.pageHeading()};
}

.sc-bg-primary {
    background-color: ${props => props.theme.colors.primary()};
}

.sc-text-primary {
    color: ${props => props.theme.colors.primary()};
}

`

export default GlobalStyle