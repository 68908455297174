import updateTextFrame from "./updateTextFrame"


export default function writeText(text)
{
    this.targetText = text
    this.text = ""
    this.startTime = (new Date()).getTime()
    this.completed = false

    requestAnimationFrame(updateTextFrame.bind(this))
}