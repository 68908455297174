import { useValue } from "../../../../components/base/hooks/useValue"
import { addChapter } from "./functions/addChapter"
import { removeChapter } from "./functions/removeChapter"

export const useStoryStore = (StoryStore) => (typeof StoryStore.get !== "undefined") ? StoryStore.get() : StoryStore


export const MakeStoryStore = ({storyName}) => {
    
    const state = {
        Chapters : useValue([]),
        CurrentlySelectedChapter : useValue(""),
        storyName
    }

    return {
        state,
        addChapter : addChapter(state),
        removeChapter : removeChapter(state),
        
    }
}
