import styled from "styled-components";

export const SizeButton = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    font-weight: 600;
    background-color: ${props => props.selected ? props.theme.colors.primary() : "#eee"};
`
export const ColorButton = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 32px;
    border-radius: 8px;
    font-weight: 600;
    background-color: ${props => props.selected ? props.theme.colors.primary() : "#eee"};
`

export const GridLayout = styled.div`
    display: grid;
    width: fit-content;
    grid-template-areas: 
        'gallery title'
        'gallery price'
        'gallery description'
        'gallery size'
        'gallery color'
        'gallery payment'
        'gallery realMiu';

    .item-gallery {
        grid-area: gallery;
        padding-right: 64px;
    }
    .item-title {
        grid-area: title;
    }
    .item-price {
        grid-area: price;
    }
    .item-description {
        grid-area: description;
    }
    .item-size {
        grid-area: size;
    }
    .item-color {
        grid-area: color;
    }
    .item-payment {
        grid-area: payment;
    }
    .item-realMiu {
        grid-area: realMiu;
    }

    @media screen and (max-width: 1110px) {
        grid-template-areas: 
            'title'
            'gallery'
            'price'
            'description'
            'size'
            'color'
            'payment'
            'realMiu';

        .item-gallery {
            padding-right: 0;
        }
        
    }

`

export const ProductTitle = styled.h1`
    /* tailwind: text-3xl text-left font-semibold */

    font-size: 30px;
    text-align: left;
    font-weight: 600;

    @media screen and (max-width: 1110px) {
        text-align: center;
    }
`