import styled from "styled-components";

export const Button = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    font-weight: 600;
    background-color: ${props => props.selected ? props.theme.colors.primary() : "#cbe4ff"};
`