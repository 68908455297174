import writeText from "./functions/writeText"

export const MakeProgressiveTextStore = ({onFinish = () => null, charactersPerSecond = 100}) => {
    
    const state = {
        targetText: "",
        text: "",
        divRef: null,
        charactersPerSecond,
        startTime: 0,
        completed: false,
        onFinish
    }

    return {
        state,
        setRef: (ref) => state.divRef = ref,
        setCharactersPerSecond: (charactersPerSecond) => state.charactersPerSecond = charactersPerSecond,
        writeText: writeText.bind(state)
    }
}