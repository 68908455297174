import { useValue } from '../../../hooks/useValue'
import {IconContainer, ImageSliderContainer, Item, StyledImageSlider} from './Style'
import {useRef, useEffect} from 'react'
import { MakeImageSliderStore } from './store'



export const ImageSlider = ({className,imageUrlList, storeControllerRef = null, ...props}) => {

    const imageSliderStore = MakeImageSliderStore({imageUrlList})

    const {
        state : {
            containerRef,
            preventClick,
            LeftArrowAvailable,
            RightArrowAvailable,
            currentImgIndex,
        },
        loadImages,
        goToSelected,
        onContainerClick,
        onMouseDown,
        onMouseDrag,
        onMouseEnter,
        onMouseUp,
        onNextClick,
        onPreviousClick
    } = imageSliderStore

    useEffect(() => {
        let firstImageDiv = containerRef.current.children[0]
        if(firstImageDiv)
        {
            firstImageDiv.style.backgroundImage = `url(${imageUrlList[0]})`
        }
        
        if (storeControllerRef!==null){
            storeControllerRef.current = imageSliderStore
        }
        

        containerRef.current.addEventListener("mousemove", onMouseDrag)
        containerRef.current.addEventListener("touchmove", onMouseDrag)

        containerRef.current.addEventListener("mousedown", onMouseDown)
        containerRef.current.addEventListener("touchstart", onMouseDown)

        containerRef.current.addEventListener("mouseup", onMouseUp)
        containerRef.current.addEventListener("touchend", onMouseUp)

        containerRef.current.addEventListener("mouseleave", onMouseUp)
        containerRef.current.addEventListener("mouseenter", onMouseEnter)

    }, [])

    return (
        <StyledImageSlider className={className} onClick={onContainerClick} {...props}>
            <LeftArrowAvailable render={leftArrowAvailable => <>
                {leftArrowAvailable && 
                    <IconContainer className="fa-solid fa-chevron-left left toggle-visibility" onClick={onPreviousClick}/>
                }
                {!leftArrowAvailable && 
                    <IconContainer className="fa-solid fa-chevron-left left toggle-visibility half-opacity" onClick={e => e.stopPropagation()}/>
                }
            </>}/>
            <RightArrowAvailable render={rightArrowAvailable => <>
                {rightArrowAvailable && 
                    <IconContainer className="fa-solid fa-chevron-right right toggle-visibility" onClick={onNextClick}/>
                }
                {!rightArrowAvailable && 
                    <IconContainer className="fa-solid fa-chevron-right right toggle-visibility half-opacity" onClick={e => e.stopPropagation()}/>
                }
            </>}/>
            
            
            <ImageSliderContainer ref={containerRef}> 
                {imageUrlList.map((_,index) => <Item key={"img_"+index}/>)}
            </ImageSliderContainer>
            
        </StyledImageSlider>
    )
}