import { useValue } from "../../components/base/hooks/useValue"
import requestUserData from "./functions/requestUserData"
import loadUser from "./functions/loadUser"
import signIn from "./functions/signIn"
import localSignOut from "./functions/localSignOut"

const MakeGlobalAuthStore = () => {
    const state = {
        token: null,
        username: null,
        accountType: null, // enum: ["ADMIN, "VENDOR"]
        Loaded: useValue(false),
        isAuthenticated: false,
        LatestLoginTime: useValue(0)
    }

    return {
        state,
        getIsAuthenticated: () => state.isAuthenticated,
        getAccountType: () => state.accountType,
        loadUser: loadUser.bind(state),
        signIn: signIn.bind(state),
        localSignOut: localSignOut.bind(state),
        requestUserData: requestUserData.bind(state)
    }
}

export const authStore = MakeGlobalAuthStore()

export const useAuthStore = () => {
    return authStore
}