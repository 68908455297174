import styled from 'styled-components';

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 56px);

    @media screen and (min-width: 640px){
        flex-direction: row-reverse;
        height: calc(100dvh - 56px);
    }
`

export const Navigation = styled.div`
    width: 100%;
    height: 56px;
    /*box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);*/
    border-top: 1px solid #ddd;
    padding-top: 0px;

    @media screen and (min-width: 640px) {
        padding-top: 12px;
        height: calc(100vh - 56px);
        height: calc(100dvh - 56px);
        width: 96px;
        /*box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1);*/
        border-right: 1px solid #ddd;
        border-top: 0px solid #ddd;
    }
`

export const NavigationMenuContainer = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 2px;

    @media screen and (min-width: 640px){
        flex-direction: column;
        gap: 32px;
    }
`

export const BodySection = styled.div`
    flex: 1;
    position: relative;
    height: calc(100vh - 112px);
    height: calc(100dvh - 112px);
    overflow-y: auto;

    @media screen and (min-width: 640px) {
        height: calc(100vh - 56px);
        height: calc(100dvh - 56px);
    }
`

export const NavigationIcon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: ${props => props.theme.colors.navigationButton()};
    margin: 4px 0px;
    padding: 0px 0px;
    ${props => props.selected ? `
        background-color: ${props.theme.colors.navigationButtonBG()};
        border-radius: 8px;
        margin: 0px 0px;
        padding: 4px 8px;
        color: white;
    ` : ''};

    div {
        font-size: 16px;
    }

    i {
        font-size: 20px;
    }

    @media screen and (min-width: 640px){
        margin: 0px 8px;
        padding: 8px 0px;
    }
`