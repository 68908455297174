import { useValue } from "../../components/base/hooks/useValue";
import { Story } from "./Story";
import { MakeGameEnginePageStore } from "./store";

export const GameEnginePage = () => {

    const gameEngineStore = MakeGameEnginePageStore()

    const {
        state: {
            Stories,
            CurrentlySelectedStory
        }
    } = gameEngineStore




    return (
        <div>
            <CurrentlySelectedStory render={currentlySelectedStory => (
                <div>
                    {
                        currentlySelectedStory &&
                        <>
                            <Story />
                        </>
                    }
                </div>
            )} />
        </div>
    )
}