import api, { setAuthToken } from "../../../util/api/index.js"

export default async function signIn({username, password})
{
    const {data} = await api.post('/auth/sign-in', {username, password})

    this.token = data.token
    this.accountType = data.accountType
    this.username = data.username
    this.isAuthenticated = true

    setAuthToken(data.token)

    if(window.ReactNativeWebView)
    {
        window.ReactNativeWebView.postMessage(JSON.stringify({
            callback: "",
            type: "SET_AUTH_TOKEN",
            payload: data.token
        }))
    }
    else
    {
        localStorage.setItem('token', data.token)
    }

    this.LatestLoginTime.set((new Date()).getTime())
    this.Loaded.set(true)

    return data
}