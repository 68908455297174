import { useRef } from "react"
import { useValue } from "../../../../hooks/useValue.js"
import { loadImages } from "./functions/loadImages.js"
import { onContainerClick } from "./functions/onContainerClick.js"
import { onMouseDown } from "./functions/onMouseDown.js"
import { onMouseDrag } from "./functions/onMouseDrag.js"
import { onMouseEnter } from "./functions/onMouseEnter.js"
import { onMouseUp } from "./functions/onMouseUp.js"
import { onNextClick } from "./functions/onNextClick.js"
import { onPreviousClick } from "./functions/onPreviousClick.js"
import { goToSelected } from "./functions/goToSelected.js"

export const MakeImageSliderStore = ({imageUrlList}) => {
    const state = {
        containerRef : useRef(),
        preventClick : useRef(true),
        LeftArrowAvailable : useValue(false),
        RightArrowAvailable : useValue(imageUrlList.length > 1 ? true : false),
        dragging : false,
        prevPageX : 0,
        initialPageY : 0,
        prevScrollLeft : 0,
        currentImgIndex : useRef(0),
        secondImageLoaded : false,
        maxIndexBrowsed : 0,
        imagesLoaded : new Array(imageUrlList.length)
    }

    return {
        state,
        loadImages : loadImages(state,imageUrlList),
        onContainerClick : onContainerClick(state),
        onMouseDown : onMouseDown(state,imageUrlList),
        onMouseDrag : onMouseDrag(state),
        onMouseEnter : onMouseEnter(state,imageUrlList),
        onMouseUp : onMouseUp(state,imageUrlList),
        onNextClick : onNextClick(state,imageUrlList),
        onPreviousClick : onPreviousClick(state),
        goToSelected : goToSelected(state,imageUrlList)
    }
}