export const onMouseDown = (state,imageUrlList) => (e) => {
    state.dragging = true;

    if(e.touches)
    {
        if(!state.secondImageLoaded)
        {
            state.secondImageLoaded = true
            let children = state.containerRef.current.children
            if(children[1])
            {
                children[1].style.backgroundImage = `url(${imageUrlList[1]})`
            }
        }

        state.prevPageX = e.touches[0].clientX;
        state.initialPageY = e.touches[0].clientY;
    }
    else
    {
        state.prevPageX = e.pageX;
        state.initialPageY = e.pageY;
    }
    state.prevScrollLeft = state.containerRef.current.scrollLeft
}
