import {useRef} from "react"
import { MakeProgressiveTextStore } from "./store"

export const ProgressiveText = ({onFinish=() => null, charactersPerSecond = 100, className="", text=""}) => {

    const divRef = useRef()
    const store = useRef(MakeProgressiveTextStore({charactersPerSecond, onFinish}))

    const {
        writeText,
        setRef,
        setCharactersPerSecond
    } = store.current

    setRef(divRef)
    setCharactersPerSecond(charactersPerSecond)
    writeText(text)

    return (
        <div ref={divRef} className={className}></div>
    )
}