
import { useRef } from "react"
import { ProgressiveText } from "../../components/base/elements/text/ProgressiveText"
import { StoreLoader } from "../../components/base/util/StoreLoader"
import { AnimatimatedMessageBox } from "../../features/AnimatedMessageBox"
import { BgImage, MiuImage } from "./Style"
import { HomePageStoreContext, MakeHomePageStore } from "./store"





const Home = () => {

    const homePageStoreRef = useRef(MakeHomePageStore())

    const {
        state: {
            WelcomeText
        }
    } = homePageStoreRef.current

    return (
        <StoreLoader store={homePageStoreRef.current} Component={() =>
            <HomePageStoreContext.Provider value={homePageStoreRef.current}>
                <BgImage>
                    <div className="absolute bottom-[5px] text-center w-full">
                        <div className="inline-block w-[95%] max-w-[600px] text-md font-semibold md:text-lg lg:text-xl 2xl:text-2xl cursor-pointer relative">
                            <AnimatimatedMessageBox>
                                {WelcomeText.get().value.map((welcomeText, index) => <ProgressiveText key={index} charactersPerSecond={60} text={welcomeText} />)}
                            </AnimatimatedMessageBox>
                        </div>
                    </div>
                </BgImage>
                </HomePageStoreContext.Provider>
        } />
        
    )
}

export default Home