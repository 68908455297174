import { Suspense } from "react"
import { BodyAndNavigation } from "../../App/theme/BodyAndNavigation"
import { Route, Routes } from "react-router-dom"
import Shop from "../Shop"
import ProductPage from "../ProductPage"
import StreamPage from "../Stream"
import MembersPage from "../Members"
import GamesPage from "../Games"
import Home from "../Home"
import { Header } from "../../App/theme/Header"
import { GameEnginePage } from "../GameEngine"

export const VtuberNavigation = () => {
    return (
        <>
            <Header />
            <BodyAndNavigation>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path="shop" element={<Shop />} />
                        <Route path="shop/:urlName" element={<ProductPage />} />
                        <Route path="stream" element={<StreamPage />} />
                        <Route path="members" element={<MembersPage />} />
                        <Route path="games" element={<GamesPage />} />
                        <Route path="games/game-engine" element={<GameEnginePage />} />
                    </Routes>
                </Suspense>
            </BodyAndNavigation>
        </>
    )
}