import { useValue } from "../../../components/base/hooks/useValue"
import { load } from "./functions/load"

export const MakeShopStore = () => {

    const state = {
        Products : useValue([]),

    }

    return {
        state,
        load : load(state)
        
    }
}