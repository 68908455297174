import { setAuthToken } from "../../../util/api"

export default async function localSignOut()
{
    this.token = null
    this.accountType = null
    this.username = null
    this.isAuthenticated = false
    this.Loaded.set(true)
    setAuthToken(null)
    this.LatestLoginTime.set((new Date()).getTime())
}