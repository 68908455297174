
export const BulletPointSection = ({bulletPoints}) => {



    return (
        <div className="pb-4 px-4 item-description">
            {console.log("BulletPoints", bulletPoints)}
            <ul className="list-disc list-inside">
            {bulletPoints.map((bulletPoint, index) => 
            <li key={index}>{bulletPoint}</li>)}
            </ul>
        </div>
    )
}