import { BodyAndNavigation } from "../../App/theme/BodyAndNavigation";
import { Header } from "../../App/theme/Header";
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { StripeForm } from "./StripeForm";
import { PaypalForm } from "./PaypalForm";
import { ImageSlider } from "../../components/base/elements/controls/ImageSlider";
import { ColorButton, GridLayout, ProductTitle, SizeButton } from "./Style";
import { useValue } from "../../components/base/hooks/useValue.js";
import { MakeProductPageStore, ProductPageStoreContext } from "./store";
import { useRef } from "react";
import { StoreLoader } from "../../components/base/util/StoreLoader/index.js";
import { BulletPointSection } from "./BulletPointSection/index.js";
import { DescriptionSection } from "./DescriptionSection/index.js";
import { PriceSection } from "./PriceSection/index.js";
import { OptionSection } from "./OptionSection/index.js";
import { PaymentOptionSection } from "./PaymentOptionSection/index.js";
import { ImageSection } from "./ImageSection/index.js";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)

const ProductPage = () => {


    const productPageStore = MakeProductPageStore()

    const {
        state: {
            Color,
            Size,
            Products,
            imageSliderStoreRef
        },
        load
    } = productPageStore


    return (

        <StoreLoader store={productPageStore} Component={() =>
            <ProductPageStoreContext.Provider value={productPageStore}>
                <GridLayout>
                    <div className="item-gallery">
                        <div className="w-[512px] max-w-[100vw] h-[512px] max-h-[100vw]">
                            <ImageSlider
                                imageUrlList={Products.get().images}
                                arrowPosition={'inside'}
                                storeControllerRef={imageSliderStoreRef}
                            />
                        </div>
                    </div>
                    <div>
                        {Products.get().section.map((section, index) => {
                            switch (section.type) {
                                case "DESCRIPTION":
                                    return <DescriptionSection text={section.props.text} key={index} />

                                case "BULLET_POINTS":
                                    return <BulletPointSection bulletPoints={section.props.bulletPoints} key={index} />

                                case "PRICE":
                                    return <PriceSection price={Products.get().priceInCents} shipping={Products.get().shippingInCents} key={index} />
                                case "OPTION":
                                    return <OptionSection optionType={section.props.optionType} Color={Color} Size={Size} label={section.props.label} options={section.props.options} key={index} />
                                case "PAYMENT":
                                    return <PaymentOptionSection key={index} paymentOption={section.props.type}/>
                                case "IMAGE":
                                    return <ImageSection url={section.props.url} key={index} />
                                default:
                                    return null
                            }
                        })}

                    </div>
                    <ProductTitle className="item-title">{Products.get().name}</ProductTitle>

                </GridLayout>
            </ProductPageStoreContext.Provider>
        } />
    );
}

export default ProductPage