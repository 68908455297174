import { useEffect } from 'react'
import {useValue} from '../../components/base/hooks/useValue.js'
import {scriptLoader} from '../../components/base/util/scriptLoader/index.js'
import {SpinnerThree} from '../../components/base/elements/loaders/SpinnerThree/index.js'
import api from '../../util/api/index.js'
import styled from "styled-components";

export const PaypalButtonsContainer = styled.div`
    background-color: #f8f8f8;
    border-radius: 9px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    padding: 32px;
    width: 400px;
    max-width: calc(100vw - 32px);
    /*margin: auto;*/
`

export const PaypalForm = ({getProductData, onPurchase = () => null, paypalClientId}) => {

    const PaypalLoaded = useValue(false)
    let paypalButtons = null
    let intent = "capture"



    const loadPaypal = async () => {
        try
        {
            await scriptLoader.loadScriptFromURL(process.env.REACT_APP_PAYPAL_SDK_URL+`?client-id=${paypalClientId}&enable-funding=venmo&currency=USD&intent=capture`)
            paypalButtons = window.paypal.Buttons({
                displayOnly: ["vaultable"],
                createOrder: () => {
                    return (async () => {
                        let {data} = await api.post('/orders/create', getProductData())
                        return data.id
                    })()
                },
                onApprove: (paypalData) => {
                    let orderId = paypalData.orderID

                    return (async () => {
                        try
                        {
                            if(intent === "authorize")
                            {
                                console.log("orderId",orderId)
                                let {data} = await api.post('/orders/authorize', {
                                    orderId
                                })
                                paypalButtons.close()
                                //TODO: redirect to thank you page
                                onPurchase(data)
                            }
                            else
                            {
                                console.log("orderId",orderId)
                                let {data} = await api.post('/orders/capture', {
                                    orderId
                                })
                                paypalButtons.close()
                                //TODO: redirect to thank you page
                                onPurchase(data)
                            }
                        }
                        catch(err)
                        {
                            console.log(err)
                            alert(err.message)
                        }
                    })()
                }
            })
            paypalButtons.render('#payment_options')
            PaypalLoaded.set(true)
        }
        catch(err)
        {
            console.log(err)
            alert(err.message)
        }
    }

    useEffect(() => {
        loadPaypal()
    }, [])

    return (
        <div>
            <PaypalLoaded render={paypalLoaded => <>
                <PaypalButtonsContainer>
                    {!paypalLoaded && <SpinnerThree color="#444444" size="50px"/>}
                    <div id="payment_options"></div>
                </PaypalButtonsContainer>
            </>} />
        </div>
    )
}