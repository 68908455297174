export const onMouseDrag = (state) => (e) => {
    if(!state.dragging) return;

    if(e.touches)
    {
        state.containerRef.current.scrollLeft = state.prevScrollLeft - e.touches[0].clientX + state.prevPageX;
        let distance = Math.abs(e.touches[0].clientY - state.initialPageY)
    }
    else
    {
        state.containerRef.current.scrollLeft = state.prevScrollLeft - e.pageX + state.prevPageX;
        let distance = Math.abs(e.pageY - state.initialPageY)
    }
}