import { useValue } from "../../../components/base/hooks/useValue"

export const MakeGameEnginePageStore = () => {
    
    const state = {
        Stories : useValue(["Story 1"]),
        CurrentlySelectedStory : useValue("Story 1"),
    }

    return {
        state,
        
    }
}
