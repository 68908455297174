import api, {setAuthToken} from "../../../util/api"

export default async function requestUserData()
{
    let storedToken = null;

    if(window.ReactNativeWebView)
    {
        storedToken = await new Promise(resolve => {
            const getAuthTokenFromLocalStorage = (token) => {
                resolve(token)
            }

            window.getAuthTokenFromLocalStorage = getAuthTokenFromLocalStorage

            window.ReactNativeWebView.postMessage(JSON.stringify({
                callback: "getAuthTokenFromLocalStorage",
                type: "GET_AUTH_TOKEN",
                payload: ""
            }))
        })
    }
    else
    {
        storedToken = localStorage.getItem('token')
    }
    if(!storedToken)
    {
        this.isAuthenticated = false
        this.Loaded.set(true)
        return
    }

    try
    {
        setAuthToken(storedToken)
        const {data} = await api.get('/auth/me')

        this.token = storedToken
        this.username = data.username
        this.accountType = data.accountType
        this.isAuthenticated = true
        this.Loaded.set(true)
        this.LatestLoginTime.set((new Date()).getTime())
    }
    catch(err)
    {
        console.log(err)
        //TODO: alert(err.message)
    }
}