import { Chapter } from "../Chapter";
import { MakeGameEnginePageStore } from "../store";
import { MakeStoryStore } from "./store";
import { useValue } from "../../../components/base/hooks/useValue";
import { Button } from "./style";



export const Story = () => {
    const storyStore = MakeStoryStore("Story 1")

    const handleSelectChange = (event) => {
        CurrentlySelectedChapter.set(event.target.value);
    };

    const {
        state: {
            Chapters,
            CurrentlySelectedChapter
        },
        addChapter
    } = storyStore

    return (
        <div>
            <CurrentlySelectedChapter render={currentlySelectedChapter => (
                <select value={currentlySelectedChapter} onChange={handleSelectChange}>
                    <option value="">Select an option</option>
                    {<Chapters mapArray={chapter =>
                        <option key={chapter} value={chapter}>
                            {chapter}
                        </option>
                    } />}
                </select>


            )} />
            <button className="bg-indigo-200 w-24 rounded"onClick={() => addChapter("Chapter 1")}> Add Chapter</button>
            <CurrentlySelectedChapter render={currentlySelectedChapter => (
                <div>
                    {
                        currentlySelectedChapter &&
                        <>
                            <Chapter />
                            
                        </>
                    }
                    
                </div>
            )} />
        </div>
    )
}