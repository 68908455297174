import axios from 'axios'
import { authStore } from '../../stores/GlobalAuthStore'

let baseUrl = '/api'
if(process.env.NODE_ENV === "test")
{
  baseUrl = 'http://localhost:5006/api'
}

// create instance of axios
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

/*
api.interceptors.request.use(
  (req) => {
    console.log("*******************************************")
    console.log(JSON.stringify(req))
    console.log("*******************************************")

    return req
  }
)
*/

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if(err.code && err.code === "ERR_NETWORK")
    {
      throw new Error("network error")
    }
    else if (err.response.status === 401) {
      authStore.localSignOut()
      //throw new Error(err.response.data.error)
    } else if (err.response.status === 404) {
      throw new Error('server path not found')
    } else if (err.response.status === 500) {
      throw new Error('server error')
    } else {
      //Do nothing
    }

    if(!err.response.data.message && !err.response.data.error)
    {
      throw new Error("A problem occured...")
    }

    throw new Error(err.response.data.message || err.response.data.error)
  }
)


export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = token

    if(window.ReactNativeWebView)
    {
        window.ReactNativeWebView.postMessage(JSON.stringify({
            callback: "",
            type: "SET_AUTH_TOKEN",
            payload: token
        }))
    }
    else
    {
        localStorage.setItem('token', token)
    }
    
  } else {
    delete api.defaults.headers.common['Authorization']
    localStorage.removeItem('token')
  }
}

export default api
