import { loadImages } from "./loadImages";

export const onMouseUp = (state, imageUrlList) => (e) => {
    let currentScroll = state.containerRef.current.scrollLeft;

    if(state.dragging)
    {
        if(Math.abs(currentScroll - state.prevScrollLeft) < 5)
        {
            state.preventClick.current = false
        }
    }
    else
    {
        return
    }

    state.dragging = false
    

    let width = state.containerRef.current.getBoundingClientRect().width;
    let prevIndex = Math.floor(currentScroll/width)
    let nextIndex = Math.ceil(currentScroll/width)

    if(state.currentImgIndex.current == prevIndex)
    {
        if(currentScroll - prevIndex*width > width*0.15)
        {
            state.currentImgIndex.current++
        }
    }
    else
    {
        if(nextIndex*width - currentScroll > width*0.15)
        {
            state.currentImgIndex.current--
        }
    }

    if(state.currentImgIndex.current < 0)
    {
        state.currentImgIndex.current = 0
    }
    else if(state.currentImgIndex.current >= imageUrlList.length)
    {
        state.currentImgIndex.current = imageUrlList.length - 1
    }

    loadImages()

    state.containerRef.current.scrollTo({
        left: width*state.currentImgIndex.current,
        behavior: "smooth",
    });

    setTimeout(() => {
        state.containerRef.current.scrollTo({
            left: width*state.currentImgIndex.current,
            behavior: "smooth",
        });
    }, 50)

    if(state.currentImgIndex.current === 0 && state.LeftArrowAvailable.get())
    {
        state.LeftArrowAvailable.set(false)
    }
    else if(state.currentImgIndex.current === imageUrlList.length - 1 && state.RightArrowAvailable.get())
    {
        state.RightArrowAvailable.set(false)
    }

    if(!state.LeftArrowAvailable.get() && state.currentImgIndex.current > 0)
    {
        state.LeftArrowAvailable.set(true)
    }
    if(!state.RightArrowAvailable.get() && state.currentImgIndex.current+1 < imageUrlList.length)
    {
        state.RightArrowAvailable.set(true)
    }

}