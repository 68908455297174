

export default function updateTextFrame()
{
    let deltaTime = (new Date()).getTime() - this.startTime
    let characters = Math.floor(deltaTime / 1000 * this.charactersPerSecond)
    this.text = this.targetText.substring(0, characters)
    
    if(this.divRef.current)
    {
        this.divRef.current.innerText = this.text
    }

    if (characters >= this.targetText.length)
    {
        this.completed = true
        this.onFinish()
    }
    else
    {
        requestAnimationFrame(updateTextFrame.bind(this))
    }
}
