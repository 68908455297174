import { ColorOptionSection } from "./ColorOptionSection";
import { SizeOptionSection } from "./SizeOptionSection";


export const OptionSection = ({optionType, label, options, Color, Size}) => {
  return (
    <div>

    
    {optionType === "SIZE" &&
        <SizeOptionSection label={label} options={options} Size={Size}/>
    }
      {optionType === "COLOR" && 
        <ColorOptionSection label={label} options={options} Color={Color} />
    }

    
    </div>
  );
}