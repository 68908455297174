import { MakeAnimnatedMessageBoxStore } from "./store"
import { useValue } from '../../components/base/hooks/useValue'
import { Box, Style } from "./Style"
import React, { useEffect, useRef } from "react"

const DummyChild = ({onFinish}) => {

    onFinish()

    return (
        <h1>No children were passed</h1>
    )
}

export const AnimatimatedMessageBox = ({children = <DummyChild />, className=""}) => {

    if(!Array.isArray(children))
    {
        children = [children]
    }

    const ChildIndexToRender = useValue(-1)
    let isVisible = false
    let isFinished = false
    const boxRef = useRef()

    const onBoxClick = () => {
        if(!isFinished)
        {
            return
        }

        isFinished = false

        let nextIndex = ChildIndexToRender.get() + 1
        
        if(nextIndex >= children.length)
        {
            boxRef.current.classList.remove("box-visible")
        }

        ChildIndexToRender.set(nextIndex)
    }

    useEffect(() => {
        boxRef.current.classList.add("box-visible")
        ChildIndexToRender.set(0)
    }, [])
    
    return (
        <Style>
            <Box ref={boxRef} className={className} isVisible={isVisible} onClick={onBoxClick}>
                <ChildIndexToRender render={childIndexToRender => <>
                    {childIndexToRender < 0 && <span>&nbsp;</span>}
                    {childIndexToRender >= 0 && childIndexToRender < children.length &&
                        React.cloneElement(children[childIndexToRender], {onFinish: () => isFinished = true})
                    }
                    {childIndexToRender >= children.length &&
                        <></>
                    }
                </>} />
            </Box>
        </Style>
    )
}