import { loadImages } from "./loadImages";

export const onNextClick = (state,imageUrlList) => (e) => {
        
    e.stopPropagation()
    
    if(state.currentImgIndex.current + 1 < imageUrlList.length)
    {
        state.currentImgIndex.current++;

        loadImages(state,imageUrlList)()

        state.containerRef.current.scrollTo({
            left: state.containerRef.current.getBoundingClientRect().width*state.currentImgIndex.current,
            behavior: "smooth",
        });

        setTimeout(() => {
            state.containerRef.current.scrollTo({
                left: state.containerRef.current.getBoundingClientRect().width*state.currentImgIndex.current,
                behavior: "smooth",
            });
        }, 50)

        if(!state.LeftArrowAvailable.get())
        {
            state.LeftArrowAvailable.set(true)
        }

        if(state.currentImgIndex.current + 1 >= imageUrlList.length)
        {
            state.RightArrowAvailable.set(false)
        }
    }
}