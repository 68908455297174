import { createContext, useContext, useRef } from "react"
import { useValue } from "../../../components/base/hooks/useValue.js"
import { load } from "./functions/load.js"

export const ProductPageStoreContext = createContext()

export const useProductPageStore = () => useContext(ProductPageStoreContext)

export const MakeProductPageStore = () => {
    const state = {
        Color : useValue("PINK"),
        Size : useValue("M"),
        Products : useValue([]),
        imageSliderStoreRef : useRef()

    }

    return {
        state,
        load : load(state)
        
    }


}