import { useValue } from "../../../../components/base/hooks/useValue"
import { PaypalForm } from "../../PaypalForm"
import { useProductPageStore } from "../../store"

export const PaypalPaymentSection = () => {
    const OrderId = useValue(null)

    const {
        state : {
            Color,
            Size,
            Products
        }
    } = useProductPageStore()
    
    const getProductData = () => {
        return {
            //productId: "65f70e44fc274d2b417b6e4b",
            //size: SelectedSize.get()
            productName: `${Products.get().name}`,
            quantity: 1,
            description: `{color:${Color.get()},size:${Size.get()}}`
        }
    }

    const onPurchase = (data) => {
        console.log("onPurchase", data)
        OrderId.set(data.id)
    }


    return (
        <div>

            <OrderId render={orderId => <>
                {orderId === null &&
                    <div className="pb-4 px-4 item-payment">
                        <PaypalForm getProductData={getProductData} onPurchase={onPurchase} paypalClientId={Products.get().paypalClientId} />
                    </div>
                }
                {orderId !== null &&
                    <div className="border border-gray-300 rounded-lg p-2">
                        <h3 className="text-left font-semibold text-xl sc-text-primary">Thank you for your purchase!</h3>
                        <div>Here is your order confirmation ID: <span className="font-semibold">{orderId}</span></div>
                    </div>
                }
            </>} />
        </div>
    )
}