import { SizeButton } from "../../Style"

export const SizeOptionSection = ({ label, options, Size }) => {
    return (
        <div className="pb-4 px-4 item-size">
            <span className="font-semibold text-lg">{label}: </span>
            <div className="inline-block">
                <Size render={size => <>
                    <div className="flex gap-2">
                        {options.map((option, index) =>
                            <SizeButton key={option.code} selected={size === option.code} onClick={() => Size.set(option.code)}>{option.label}</SizeButton>
                        )}
                    </div>
                </>} />
            </div>
        </div>
    )
}