import { useValue } from "../../../../components/base/hooks/useValue"

export const useChapterStore = (ChapterStore) => (typeof ChapterStore.get !== "undefined") ? ChapterStore.get() : ChapterStore


export const MakeChapterStore = ({chapterName}) => {
    
    const state = {
        Scenes : useValue([]),
        CurrentlySelectedScene : useValue(""),
        chapterName
    }

    return {
        state,
        
    }
}
