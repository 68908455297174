import styled from "styled-components";

export const BgImage = styled.div`
    width: 100%;
    height: calc(100vh - 112px);
    height: calc(100dvh - 112px);
    background-image: url('/images/homepage-bg.jpg');
    background-size: cover;
    background-position: center;
    position: relative;

    @media screen and (min-width: 640px) {
        height: calc(100vh - 56px);
        height: calc(100dvh - 56px);
    }
`

export const MiuImage = styled.img`
    position: absolute;
    max-height: calc((100vh - 112px) * 0.85);
    max-height: calc((100dvh - 112px) * 0.85);
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
`