import { useNavigate, useParams } from "react-router-dom"
import { BodyAndNavigation } from "../../App/theme/BodyAndNavigation"
import { Header } from "../../App/theme/Header"
import { ProductCard, ProductImage, ProductTitle } from "./Style"
import { StoreLoader } from "../../components/base/util/StoreLoader"
import { MakeShopStore } from "./store"
import { useRef } from "react"

const Shop = () => {

    const shopStore = useRef(MakeShopStore())

    const {
        state: {
            Products
        },
        load
    } = shopStore.current


    const params = useParams()
    const navigate = useNavigate()

    const onProductClick = (urlName) => {
        navigate('/' + params.username +'/shop/'+ urlName)
    }

    return (
        <StoreLoader store={shopStore.current} Component={() =>
            <>
                <h1 className="text-3xl p-4 font-bold sc-color-pageHeading">Miu's merch! Yay!</h1>
                <Products mapArray= {product => 
                <div key="key" className="p-4">
                    <ProductCard onClick={() => onProductClick(product.urlName)} productImage={product.images[0]} hoverImage={product.hoverImage}>
                        <div className="secondaryImage"></div>
                        <div className="title">{product.name}</div>
                    </ProductCard>
                </div>
                }/>
            </>
        } />

    )
}

export default Shop