import styled from "styled-components";

export const ProductCard = styled.div`
    position: relative;
    width: 256px;
    height: 256px;
    background-color: #aaa;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.4s;
    cursor: pointer;
    border-radius: 12px;
    background-image: url(${props => props.productImage || ""});
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .title {
        font-size: 24px;
        padding: 4px 0px;
        margin: 0;
        text-align: center;
        position: absolute;
        bottom: 0;
        background-color: ${props => props.theme.colors.primary(0,0,0.6)};
        color: ${props => props.theme.colors.black()};
        border-radius: 8px;
        width: 100%;
        transition: all 0.4s;
    }

    .secondaryImage {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 0px;
        background-color: green;
        transition: all 0.4s;
        background-image: url(${props => props.hoverImage || ""});
        background-size: cover;
    }

    &:hover {
        .title {
            bottom: 100%;
        }

        .secondaryImage {
            height: 256px;
        }

        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
`