import { MakeChapterStore } from "../../../Chapter/store"


export const addChapter = (state) => (chapterName) => {

    console.log("chapter :", chapterName)

    let newItem = MakeChapterStore({ chapterName : chapterName})

   

    state.Chapters.set([...state.Chapters.get(), newItem])

    console.log(state.Chapters.get())
}
